body {
	font-family: 'Encode Sans', sans-serif;
	color: #000;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 400;
}

h1 {
	margin-top: 0px;
	margin-bottom: 0px;
	padding-top: 0.43rem;
	padding-bottom: 0.43rem;
	font-family: Raleway, sans-serif;
	color: #014f81;
	font-size: 2.75rem;
	line-height: 3.5rem;
	font-weight: 600;
}

h2 {
	margin-top: 1.5rem;
	margin-bottom: 0.7rem;
	font-family: Raleway, sans-serif;
	color: #034a94;
	font-size: 1.75rem;
	line-height: 2.25rem;
	font-weight: 600;
}

h3 {
	margin-top: 1.5rem;
	margin-bottom: 0.7rem;
	font-family: Raleway, sans-serif;
	color: #034a94;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 600;
}

h4 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-family: Raleway, sans-serif;
	color: #034a94;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 600;
}

h5 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-family: Raleway, sans-serif;
	color: #034a94;
	font-size: 1.15rem;
	line-height: 1.65rem;
	font-weight: 600;
}

h6 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-family: Raleway, sans-serif;
	font-size: 1.05rem;
	line-height: 1.55rem;
	font-weight: 600;
}

p {
	margin-bottom: 1rem;
}

a {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: underline;
}

ul {
	margin-top: 0px;
	margin-bottom: 1rem;
	padding-left: 20px;
}

ol {
	margin-top: 0px;
	margin-bottom: 10px;
	padding-left: 40px;
}

label {
	display: block;
	margin-bottom: 5px;
	font-size: 0.8571rem;
	line-height: 1.5rem;
	font-weight: 700;
}

blockquote {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	padding: 10px 20px;
	border-left: 5px solid #4578bc;
	color: #424242;
	font-size: 1.15rem;
	line-height: 1.85rem;
	font-weight: 500;
}

.container {
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.container.footer-container {
	display: block;
	max-width: 1500px;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.container.signoff-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1500px;
	padding: 0.5rem 1.25rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.container.news-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: none;
	margin-right: 0px;
	margin-left: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.container.doc-rpt-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	margin-right: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.container.page-title-container {
	position: relative;
	left: 0px;
	right: 0px;
	display: block;
	width: 100%;
	max-width: 1500px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 2rem;
	padding-left: 4vw;
	background-color: hsla(0, 0%, 100%, 0.75);
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.container.inside-page-container {
	max-width: none;
}

.container.inside-content-container {
	padding-top: 2rem;
	padding-right: 6.5vw;
	padding-left: 6.5vw;
	background-color: hsla(0, 0%, 100%, 0.9);
}

.logo {
	width: 100%;
}

.logo-link {
	display: block;
	width: 150px;
	margin-right: 3rem;
	margin-left: 0vw;
	padding: 0.5rem 0rem 0rem;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	cursor: pointer;
}

.main-nav-link {
	position: relative;
	display: block;
	padding: 0.5rem 1.25rem;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
	color: #000;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.main-nav-link.w--current {
	background-color: rgba(3, 52, 102, 0.6);
	color: #f9b604;
}

.main-nav-link.mobile-duplicate {
	display: none;
}

.dropdown-list {
	position: absolute;
	top: 43px;
	display: none;
	height: 0px;
	border-top: 4px solid #f9b604;
	background-color: hsla(0, 0%, 100%, 0.93);
	box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
	opacity: 0;
}

.dropdown-list.w--open {
	left: 0px;
	top: 43px;
	min-width: 200px;
	padding: 0.25rem 0.25rem 0.5rem;
	background-color: #ffdf1c;
}

.slideshow-arrow {
	left: auto;
	top: auto;
	right: 0px;
	bottom: 0px;
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
	background-color: rgba(69, 120, 188, 0.75);
}

.slideshow-arrow.left-arrow {
	left: 4vw;
	right: auto;
	bottom: 1rem;
	display: block;
	border-radius: 50%;
	background-color: rgba(69, 120, 188, 0.75);
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
}

.slideshow-arrow.left-arrow:hover {
	background-color: #4578bc;
}

.slideshow-arrow.right-arrow {
	left: 4vw;
	right: auto;
	bottom: 1rem;
	margin-left: 2.4rem;
}

.slideshow-arrow.right-arrow:hover {
	background-color: #4578bc;
}

.home-content-section {
	display: block;
	padding: 3rem 6vw;
}

.home-content-section.hcs-row-1 {
	position: relative;
	padding-top: 3rem;
	padding-bottom: 3rem;
	background-image: url('/20170713122722/assets/images/black-twill-flop-10_1black-twill-flop-10.png'), -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.73)), to(hsla(0, 0%, 100%, 0.73))), -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.73)), to(hsla(0, 0%, 100%, 0.73)));
	background-image: url('/20170713122722/assets/images/black-twill-flop-10_1black-twill-flop-10.png'), linear-gradient(180deg, hsla(0, 0%, 100%, 0.73), hsla(0, 0%, 100%, 0.73)), linear-gradient(180deg, hsla(0, 0%, 100%, 0.73), hsla(0, 0%, 100%, 0.73));
	text-align: left;
}

.home-content-section.hcs-row-2 {
	position: relative;
	padding-top: 3rem;
	padding-bottom: 0rem;
	text-align: left;
}

.home-content-section.hcs-row-3 {
	padding: 6rem 4vw;
	text-align: left;
}

.footer-section {
	padding-top: 3rem;
	background-color: #f9f9f9;
	background-image: url('/20170713122722/assets/images/black-twill-flop-10_1black-twill-flop-10.png');
	background-position: 0px 0px;
	background-size: 7px;
}

.signoff-wrapper {
	position: relative;
	margin-top: 1rem;
	padding-bottom: 1.25rem;
	background-color: #384048;
}

.footer-grip-link {
	display: block;
	width: 130px;
	height: 28px;
	min-width: 0px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 32px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	opacity: 0.75;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
	cursor: pointer;
}

.footer-grip-link:hover {
	opacity: 1;
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}

.grip-logo-text-box {
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #fff;
	font-size: 8px;
	line-height: 8px;
	text-decoration: none;
	text-transform: uppercase;
}

.copyright-paragraph {
	display: inline-block;
	margin-right: 8px;
	margin-bottom: 0rem;
	color: #dadada;
	font-size: 0.8rem;
	font-weight: 400;
}

.signoff-link {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #dadada;
	font-size: 0.8rem;
	text-decoration: none;
}

.signoff-link:hover {
	color: #fff;
}

.footer-paragraph {
	margin-bottom: 0.65rem;
	font-size: 0.9rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.footer-nav-list {
	-webkit-column-count: 1;
	column-count: 1;
}

.footer-nav-list-item {
	display: block;
	margin-bottom: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	font-weight: 400;
}

.footer-list-item-link {
	padding-right: 0.5rem;
	color: hsla(0, 0%, 100%, 0.85);
	text-decoration: none;
}

.footer-list-item-link:hover {
	color: #fff;
}

.social-media-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.social-media-wrapper.footer-socmed-wrapper {
	float: right;
}

.social-media-wrapper.footer-socmed-wrapper.social-2 {
	margin-top: 0.5rem;
	float: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.social-media-wrapper.footer-socmed-wrapper.social-2 {
	float: none;
}

.copyright-wrapper {
	display: inline-block;
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.textlink-middleout {
	color: #034a94;
	font-weight: 500;
	text-decoration: none;
}

.textlink-middleout:hover {
	box-shadow: inset 0 -1px 0 0 #629edc;
}

.textlink-middleout.footer-list-item-link {
	padding-right: 0rem;
	color: #2e2e2e;
	font-size: 0.9rem;
	line-height: 1.5rem;
	font-weight: 400;
	cursor: pointer;
}

.textlink-middleout.footer-list-item-link:hover {
	box-shadow: inset 0 -1px 0 0 #629edc;
}

.textlink-middleout.signoff-link {
	display: inline-block;
	color: #dadada;
	font-weight: 400;
}

.textlink-middleout.signoff-link:hover {
	box-shadow: inset 0 -1px 0 0 #629edc;
}

.footer-social-icon-image {
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.locations-options-wrapper {
	display: block;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.more-options-button {
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #221f1f;
	font-size: 0.95rem;
	line-height: 0.95rem;
	font-weight: 500;
	text-decoration: none;
	text-transform: uppercase;
}

.footer-heading {
	margin-top: 0rem;
	font-size: 1.5rem;
	line-height: 1.75rem;
	font-weight: 600;
}

.privacy-terms-wrapper {
	display: inline-block;
}

.less-options-button {
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #221f1f;
	font-size: 0.95rem;
	line-height: 0.95rem;
	font-weight: 500;
	text-decoration: none;
	text-transform: uppercase;
}

.feature-card-bg-image {
	height: 200px;
	margin-top: 4px;
	margin-right: 4px;
	margin-left: 4px;
	background-image: url('/20170713122722/assets/images/placeholder-image.png');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.feature-card-heading {
	display: block;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding: 0px 0rem;
	font-family: 'Chunkfive webfont';
	color: #fff;
	font-size: 1.5rem;
	line-height: 1.9rem;
	font-weight: 400;
	text-align: right;
	letter-spacing: 0.05rem;
}

.nav-dd-triangle {
	position: absolute;
	right: 50%;
	bottom: 0px;
	display: none;
	border-right: 7px solid transparent;
	border-bottom: 8px solid #f9b604;
	border-left: 7px solid transparent;
}

.backk-to-top {
	position: absolute;
	right: 0px;
	bottom: 0px;
	display: none;
	width: 60px;
	height: 60px;
	margin-right: 0.75rem;
	padding-right: 1.5rem;
	border-radius: 2px;
	background-color: #000;
	background-image: url('/20170713122722/assets/images/circle-arrow-white.svg');
	background-position: 50% 50%;
	background-size: 20px;
	background-repeat: no-repeat;
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
	font-family: 'Fontawesome pro solid 900';
	color: #dadada;
	font-size: 0.8rem;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.cb-section-heading {
	margin-top: 0rem;
	color: #034a94;
	font-size: 2.5rem;
	line-height: 3.25rem;
	font-weight: 600;
}

.cb-section-heading.reversed {
	color: #fff;
	font-weight: 600;
}

.cb-section-heading.white-cb-head {
	color: #fff;
	font-weight: 600;
}

.feature-cb-paragraph {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	font-size: 1.25rem;
	line-height: 2.1rem;
}

.feature-cb-paragraph.white-feature-cb-para {
	color: #fff;
}

.tab-triangle {
	position: absolute;
	left: 50%;
	display: none;
	height: 16px;
	border-top: 10px solid #165b89;
	border-right: 12px solid transparent;
	border-left: 12px solid transparent;
	opacity: 0;
	-webkit-transform: translate(-50%, 0px);
	-ms-transform: translate(-50%, 0px);
	transform: translate(-50%, 0px);
}

.footer-header {
	margin-top: 0rem;
	color: #034a94;
	font-size: 1rem;
	line-height: 1.5rem;
}

.footer-lower-parent {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 1rem;
	padding-bottom: 1.75rem;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	border-top: 2px solid #c3b9a4;
}

.footer-upper-parent {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 1.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #fff;
}

.header-right-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.header-right-wrap.extranet-right {
	position: static;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.header-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: transparent;
}

.spinner-country-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 150px;
	padding-top: 0.7rem;
	padding-bottom: 0.7rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 4px;
	background-color: #fff;
	background-image: url('/20170713122722/assets/images/60px_wikipedia_Flag_of_Spain_160px_wikipedia_Flag_of_Spain.png');
	background-position: 11px 50%;
	background-size: 35px;
	background-repeat: no-repeat;
}

.spinner-destination {
	margin-top: 0rem;
	margin-bottom: 0rem;
	color: #fff;
	font-size: 1.25rem;
}

.footer-cb {
	width: 20%;
}

.footer-cb.footer-cb-1 {
	width: auto;
	padding-right: 0rem;
	padding-left: 0rem;
	background-color: transparent;
}

.footer-cb.footer-cb-4 {
	width: auto;
	padding-right: 0rem;
	padding-left: 0rem;
	background-color: transparent;
}

.footer-cb.footer-cb-3 {
	width: auto;
	padding-right: 0rem;
	padding-left: 0rem;
	background-color: transparent;
}

.nav-search-button-icon {
	width: 1em;
	height: 1.375em;
}

.nav-search-button-close-icon {
	display: none;
	width: 1em;
	height: 1.375em;
}

.hero-caption-inner-wrap {
	position: relative;
	top: 50%;
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
}

.quick-search-results-outer {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	opacity: 0;
}

.content-box {
	width: 50%;
	height: auto;
	min-height: 500px;
	padding-top: 5rem;
	padding-right: 1rem;
	padding-bottom: 6rem;
}

.content-box.callout-cb {
	width: 100%;
	min-height: 0px;
	padding: 3rem 3vw;
	background-color: rgba(6, 95, 173, 0.9);
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.15)), to(rgba(0, 0, 0, 0.15)));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
	text-align: center;
}

.content-box-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 4rem;
	padding-left: 4rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 4px none #4c4c4c;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.content-box-wrap.cbw-row-1 {
	display: block;
	max-width: 1040px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0rem;
	padding-left: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-webkit-flex-direction: row-reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
}

.accent-divider {
	display: block;
	width: 100px;
	height: 2px;
	margin: 0.75rem auto;
	background-color: #c3b9a4;
}

.social-media-link-block-2 {
	width: 2.25rem;
	height: 2.25rem;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	background-position: 50% 50%;
	background-size: 25px;
	background-repeat: no-repeat;
}

.social-media-link-block-2.footer-socmed-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0.75rem;
	margin-left: 0.75rem;
	padding: 2px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border: 2px solid transparent;
	border-radius: 50%;
	background-color: #4578bc;
	background-image: none;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	cursor: pointer;
}

.social-media-link-block-2.footer-socmed-link-block:hover {
	padding: 2px;
	border: 2px solid #2a5ea3;
	background-color: #2a5ea3;
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.nav-bar-widget {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: transparent;
}

.nav-dd-list {
	background-color: #fff;
}

.nav-dd-list.w--open {
	width: auto;
	min-width: 150px;
	background-color: #fff;
	box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.05);
}

.nav-outer-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 47px;
	float: right;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.dd-link-wrap {
	padding: 10px;
}

.nav-wrap {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.header-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}

.main-nav-link-toggle {
	width: 100%;
	padding: 0px;
	box-shadow: none;
}

.main-nav-link-toggle:hover {
	box-shadow: inset 0 -2px 0 0 #629edc;
}

.main-nav-link-toggle.w--open {
	box-shadow: inset 0 -2px 0 0 #629edc;
	color: #4578bc;
}

.main-nav-dd-widget {
	margin-right: 0.6rem;
	margin-left: 0.6rem;
	color: #000;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 500;
}

.main-nav-text {
	position: relative;
	display: block;
	width: 100%;
	padding: 0.5rem;
	color: #000;
	text-decoration: none;
}

.main-nav-text:hover {
	color: #4578bc;
}

.main-nav-text.w--current {
	box-shadow: inset 0 -2px 0 0 #629edc;
}

.dd-link {
	margin-top: 1px;
	margin-bottom: 1px;
	padding: 4px 10px;
	font-size: 0.85rem;
	font-weight: 500;
	text-decoration: none;
}

.dd-link:hover {
	box-shadow: inset 2px 0 0 0 #629edc;
	color: #4578bc;
}

.dd-link.profile-dd-link {
	display: block;
	color: #fff;
}

.dd-link.profile-dd-link:hover {
	background-color: #2a5ea3;
	box-shadow: inset 2px 0 0 0 #fff;
}

.login {
	display: block;
	font-weight: 500;
	letter-spacing: 0rem;
}

.english {
	display: none;
	font-weight: 500;
	letter-spacing: 0rem;
}

.phone-link {
	color: #000;
	text-decoration: none;
	font-weight: normal;
}

.flight-results-outer-wrap {
	display: none;
	padding-top: 1.5rem;
}

.find-flight-dd {
	position: absolute;
	left: 0px;
	top: 59px;
	right: 0px;
	z-index: 1002;
	width: 452px;
	height: 0px;
	padding-top: 0rem;
	box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.05);
	opacity: 0;
}

.find-flight-dd.w--open {
	top: 100%;
	right: -24px;
	width: 452px;
	padding-top: 1.5rem;
	background-color: #fff;
	box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.05);
}

.mini-flight-results-outer-wrap {
	display: none;
	padding-top: 1.5rem;
}

.search-container-cover {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 20000;
	display: none;
	overflow: hidden;
	width: 100%;
	height: 0px;
	padding-right: 2rem;
	padding-left: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.85);
}

.extranet-button {
	display: inline-block;
	height: 44px;
	margin-top: 1rem;
	margin-right: 1rem;
	margin-bottom: 1rem;
	padding: 0.8rem 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	border-radius: 4px;
	background-color: #4578bc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	line-height: 1rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
}

.extranet-button:hover {
	background-color: #033466;
}

.extranet-button.cb-button {
	height: auto;
}

.extranet-button.cb-button:hover {
	background-color: #2a5ea3;
}

.extranet-button.home-news-button {
	width: 20%;
	height: auto;
	margin-right: 0rem;
}

.extranet-button.home-news-button:hover {
	background-color: #2a5ea3;
}

.alert-wrapper-2 {
	display: none;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding: 2.25rem 4rem 2.5rem 3rem;
	background-color: #033466;
}

.header-wrap {
	position: fixed;
	z-index: 10000;
	width: 100%;
}

.spacer {
	display: none;
	height: 209px;
}

.co-brand-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.airline-logo-wrap {
	display: block;
	margin-right: 1rem;
	padding-right: 1rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-right: 1px solid #c3b9a4;
}

.page-wrapper {
	padding-top: 122px;
}

.airline-logo {
	max-height: 45px;
	max-width: 180px;
}

.hero-slide {
	overflow: hidden;
}

.hero-image-wrap {
	position: relative;
	overflow: hidden;
	width: 80%;
	height: 100%;
	float: right;
	background-position: 0px 0px, 50% 0px;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.hero-image-wrap.hero-1 {
	background-position: 50% 50%, 0px 0px;
	background-size: cover, auto;
	background-repeat: no-repeat, repeat;
}

.slideshow-header {
	display: inline-block;
	max-width: none;
	padding: 0.5rem 1rem;
	float: left;
	border-left: 4px solid #4578bc;
	background-color: hsla(0, 0%, 100%, 0.75);
	color: #000;
	font-size: 2.75rem;
	line-height: 3.15rem;
	font-weight: 600;
	letter-spacing: 0.03rem;
	text-transform: uppercase;
}

.slideshow-caption {
	display: inline-block;
	max-width: 650px;
	margin-bottom: 0.7rem;
	padding: 0.5rem 1rem;
	float: left;
	background-color: hsla(0, 0%, 100%, 0.75);
	color: #000;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 300;
}

.hero-caption {
	position: absolute;
	left: 0px;
	top: 50%;
	bottom: 0px;
	width: 50%;
	height: 100%;
	max-width: 650px;
	min-width: 545px;
	margin-left: 4vw;
	padding-bottom: 4rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
	color: #fff;
	font-size: 1.125em;
	line-height: 1.65em;
}

.slide-nav {
	left: 4vw;
	right: auto;
	bottom: 1.25rem;
	display: block;
	width: 100px;
	height: 2rem;
	margin-right: auto;
	margin-left: 5rem;
	padding-top: 13px;
	font-size: 0.65rem;
	text-align: left;
}

.arrow-icon {
	display: block;
	margin: auto;
	padding: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transform: skew(3deg, 0deg);
	-ms-transform: skew(3deg, 0deg);
	transform: skew(3deg, 0deg);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 0.9rem;
	line-height: 0.9rem;
}

.arrow-icon:hover {
	color: #fff;
}

.arrow-icon.right-arrow-icon {
	left: 0px;
	right: 0px;
	display: block;
	margin: auto;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.header-section {
	position: relative;
	left: 0px;
	top: 0px;
	right: 0px;
	z-index: 10000;
	padding-right: 4vw;
	padding-left: 4vw;
	border-bottom: 4px solid #c3b9a4;
	background-color: #fff;
}

.hero-rotator {
	width: 100%;
	height: 100%;
	background-color: #033466;
}

.hero-rotator-section {
	position: relative;
	height: 60vh;
	max-height: 620px;
	min-height: 570px;
}

.hermes-wrap {
	margin-right: 2rem;
	padding-top: 0.5rem;
	padding-right: 2.5rem;
	padding-bottom: 1.25rem;
}

.extranet-header-right-upper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0.5rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.nav-dd-triangle-2 {
	position: absolute;
	top: 0px;
	right: 24px;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #d2d4da;
	border-left: 7px solid transparent;
}

.nav-dd-triangle-2.profile-dd-triangle {
	border-bottom-color: #cdd0d6;
}

.nav-dd-triangle-2.profile-dd-triangle.far-left-dd-triangle {
	left: 24px;
	top: 0px;
	right: auto;
	margin-top: 8px;
	border-bottom-color: #4578bc;
}

.profile-nav-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 35px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #4578bc;
	font-size: 0.85rem;
	line-height: 1em;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
}

.profile-dd-list {
	position: absolute;
	overflow: hidden;
	width: auto;
	min-width: 200px;
	padding-top: 7px;
}

.profile-dd-list.w--open {
	top: 32px;
	padding-top: 15px;
	background-color: transparent;
}

.profile-dd-toggle {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	box-shadow: none;
}

.profile-dd-toggle:hover {
	box-shadow: none;
}

.profile-dd-toggle.w--open {
	box-shadow: inset 0 -2px 0 0 #629edc;
	color: #4578bc;
}

.profile-dd-widget {
	z-index: 1100;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	color: #000;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 500;
}

.profile-dd-link-wrap {
	padding: 10px;
	background-color: #4578bc;
}

.tab-pane {
	background-color: #fff;
}

.tab-pane p.zero-results {
	padding: 20px;
	text-align: center;
}

.tabs-content-wrap {
	border-top: 1px none #4578bc;
}

.news-list-wrap {
	position: relative;
	width: 31%;
	margin-right: 0rem;
	margin-left: 0rem;
	padding: 1.5rem 1.5rem 1rem;
	border-left: 4px solid #4578bc;
	background-color: rgba(206, 230, 247, 0.7);
}

.news-title-link {
	display: inline;
	border-bottom: 1px solid transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #034a94;
	font-weight: 600;
	text-decoration: none;
	cursor: pointer;
}

.news-title-link:hover {
	border-bottom-color: rgba(69, 120, 188, 0.6);
}

.item-date {
	margin-bottom: 0.5rem;
	color: #424242;
	font-size: 0.8rem;
	line-height: 1.3rem;
	font-weight: 600;
	text-transform: uppercase;
}

.cb-parent-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-bottom: 2rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.news-feed-article-heading {
	margin-top: 0rem;
	margin-bottom: 0.25rem;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.table-header {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	border-bottom: 2px solid #4578bc;
	color: #034a94;
}

.col-heading {
	font-size: 0.95rem;
	font-weight: 600;
	text-align: left;
}

.doc-rpt-link {
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-bottom: 1px;
	font-family: Raleway, sans-serif;
	color: #034a94;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
	text-decoration: none;
	border-bottom: 0px;
}

.doc-rpt-link:hover {
	border-bottom: 1px solid rgba(69, 120, 188, 0.6);
}

.medium-cell {
	width: 77%;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.small-cell {
	width: 23%;
	min-width: 120px;
	padding-right: 0.5rem;
	padding-left: 1rem;
}

.table-parent {
	position: relative;
	padding-bottom: 3.5rem;
}

.table-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0.6rem;
	padding-bottom: 0.6rem;
	border-bottom: 1px none #cdd0d6;
	line-height: 1.4rem;
}

.table-row.shaded {
	background-color: #ebeff2;
}

.table-more {
	position: absolute;
	left: 0px;
	bottom: 0px;
	display: inline-block;
	margin-bottom: 1rem;
	margin-left: 1rem;
	padding-right: 1.4rem;
	background-image: url('/20170713122722/assets/images/arrow-right_034a94.svg');
	background-position: 94% 48%;
	background-size: 10px 10px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #034a94;
	font-size: 0.9rem;
	font-weight: 600;
	text-decoration: none;
	border-bottom: 0px;
}

.table-more:hover {
	opacity: 1;
	-webkit-transform: translate(7px, 0px);
	-ms-transform: translate(7px, 0px);
	transform: translate(7px, 0px);
	color: #4578bc;
}

.tab-text {
	font-weight: 600;
}

.doc-rpt-tab-widget {
	display: block;
	width: 100%;
	margin-top: 1rem;
	text-align: left;
}

.doc-rpt-tab-widget.tab-widget-right {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.tab-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.tablink {
	width: 49.6%;
	padding: 1.25rem 0.65rem;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	background-color: #034a94;
	background-image: none;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-family: Raleway, sans-serif;
	color: #bbb;
	font-size: 1rem;
	line-height: 1.4rem;
	font-weight: 700;
	text-align: center;
}

.tablink:hover {
	opacity: 1;
}

.tablink.w--current {
	width: 50%;
	border-bottom: 4px none #034a94;
	background-color: transparent;
	background-image: none;
	box-shadow: inset 0 -100px 0 0 #fff;
	opacity: 1;
	font-family: Raleway, sans-serif;
	color: #034a94;
	font-size: 1.25rem;
	font-weight: 600;
}

.cb-category-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 48%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.bg-image-wrapper {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(3, 74, 148, 0.7)), color-stop(75%, rgba(3, 74, 148, 0))), url('/20170713122722/assets/images/black-twill-flop-10_1black-twill-flop-10.png'), url('/20170713122722/assets/images/Clouds_1903x1269_tinified.jpg');
	background-image: linear-gradient(180deg, rgba(3, 74, 148, 0.7), rgba(3, 74, 148, 0) 75%), url('/20170713122722/assets/images/black-twill-flop-10_1black-twill-flop-10.png'), url('/20170713122722/assets/images/Clouds_1903x1269_tinified.jpg');
	background-position: 0px 0px, 0px 0px, 50% 50%;
	background-size: auto, auto, cover;
	background-repeat: repeat, repeat, no-repeat;
	background-attachment: scroll, scroll, fixed;
}

.dd-link-2 {
	margin-top: 1px;
	margin-bottom: 1px;
	padding: 4px 10px;
	font-size: 0.85rem;
	font-weight: 500;
}

.dd-link-2:hover {
	box-shadow: inset 2px 0 0 0 #629edc;
	color: #4578bc;
}

.dd-link-2.w--current {
	box-shadow: inset 2px 0 0 0 #629edc;
	color: #4578bc;
}

.article-date {
	margin-top: 10px;
	padding-left: 20px;
	background-image: url('/20170713122722/assets/images/calendar.svg');
	background-position: 0px 50%;
	background-size: 15px 15px;
	background-repeat: no-repeat;
	color: #424242;
	font-size: 0.8em;
	line-height: 1.3rem;
	font-weight: 600;
	text-transform: uppercase;
}

.left-nav-section-title {
	margin-top: 0px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.ordered-list {
	padding-left: 20px;
}

.heading-link {
	border-bottom: 1px solid #629edc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #034a94;
	text-decoration: none;
	cursor: pointer;
}

.heading-link:hover {
	border-bottom-color: transparent;
	color: #7c8186;
}

.left-nav-wrapper {
	margin-right: 1rem;
	margin-left: 0rem;
}

.content-outer-wrap {
	position: relative;
	z-index: 2;
	display: block;
	max-width: 1500px;
	margin-right: auto;
	margin-left: auto;
	padding: 0rem 4vw 4rem;
	border-top: 8px solid #4578bc;
	background-color: #fff;
}

.intro-paragraph {
	margin-bottom: 1.5rem;
	color: #424242;
	font-size: 1.75rem;
	line-height: 2.25rem;
}

.left-nav-col {
	width: 20%;
	height: 100%;
	max-width: 250px;
	min-height: 300px;
	min-width: 200px;
	margin-right: 3vw;
	padding-top: 2rem;
	padding-right: 0.75px;
	padding-bottom: 4rem;
	float: left;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.left-nav-section-title-link,
.left-nav-section-title-link:hover {
	display: block;
	padding-top: 0.25rem;
	padding-right: 0px;
	padding-bottom: 0.4rem;
	font-family: 'Encode Sans', sans-serif;
	color: #4578bc;
	font-size: 1rem;
	line-height: 1.4rem;
	font-weight: 600;
	text-decoration: none;
}

.main-content-outer-wrap {
	display: block;
	width: 100%;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.button {
	display: inline-block;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1rem;
	margin-bottom: 1rem;
	padding: 0.8rem 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	border-radius: 4px;
	background-color: #4578bc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	line-height: 1rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
}

.button:hover {
	background-color: #2a5ea3;
}

.button.secondary {
	background-color: #d6dce0;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #2e2e2e;
	text-align: center;
	cursor: pointer;
}

.button.secondary:hover {
	border-color: #221f1f;
	background-color: #c4ccd1;
	background-image: none;
	opacity: 1;
}

.button.tertiary {
	min-width: 60px;
	margin-top: 0.5rem;
	margin-right: 0rem;
	margin-bottom: 0.5rem;
	padding: 0.25rem 0.75rem;
	border: 2px solid #c4ccd1;
	background-color: transparent;
	color: #585b5d;
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.button.tertiary:hover {
	background-color: #c4ccd1;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #000;
}

.inside-content-sect {
	position: relative;
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding-right: 3vw;
	padding-left: 3vw;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #d6dce0;
}

.left-nav-li.greatgrandchild-li {
	margin-top: 2px;
	margin-left: 0.85rem;
	font-size: 0.9rem;
}

.left-nav-li.grandchild-li {
	margin-left: 0.75rem;
}

.right-column-link {
	border-bottom: 1px solid #629edc;
	color: #034a94;
	text-decoration: none;
}

.right-column-link:hover {
	border-bottom: 1px solid transparent;
}

.inside-page-banner-wrap {
	width: 100%;
	height: 450px;
	padding-right: 8rem;
	padding-left: 8rem;
	background-color: #fff;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.inside-page-banner-wrap.inside-banner-1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 3vw;
	padding-left: 3vw;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.horiz-divider {
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: rgba(0, 0, 0, 0.15);
}

.text-link {
	border-bottom: 1px solid #629edc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #034a94;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.text-link:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
}

.main-content {
	display: block;
	width: 100%;
	padding-top: 2rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.main-content.no-sidebars {
	display: block;
	max-width: 1024px;
	margin-right: auto;
	margin-left: auto;
}

.breadcrumbs-list {
	margin-bottom: 2rem;
	padding-left: 0px;
}

.right-side-col {
	position: relative;
	width: 16%;
	max-width: 250px;
	min-width: 220px;
	margin-left: 3vw;
	padding-top: 1rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.right-side-col h2 {
	margin-top: 1.25rem;
	margin-bottom: 0.25rem;
	color: #034a94;
	font-size: 1.1rem;
	line-height: 1.6rem;
}

.right-side-col p {
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.right-side-col ul {
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.right-side-col ol {
	padding-left: 20px;
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.right-side-col a {
	border-bottom: 1px solid #629edc;
	color: #034a94;
	font-weight: 500;
	text-decoration: none;
}

.right-side-col h3 {
	margin-top: 1.25rem;
	margin-bottom: 0.25rem;
	color: #034a94;
	font-size: 1.1rem;
	line-height: 1.6rem;
}

.right-side-col h4 {
	margin-bottom: 0.25rem;
	color: #034a94;
	font-size: 1.1rem;
	line-height: 1.6rem;
}

.breadcrumb-divider {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	color: #424242;
	font-size: 1rem;
}

.main-content-inner-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.breadcrumbs-list-item {
	display: inline-block;
	float: left;
}

.left-nav-ul {
	display: block;
	margin-bottom: 0.5rem;
	background-color: transparent;
}

.breadcrumb-link {
	border-bottom: 1px solid transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #424242;
	font-size: 0.8rem;
	line-height: 1.5rem;
	text-decoration: none;
	cursor: pointer;
}

.breadcrumb-link:hover {
	border-bottom-color: #727780;
	color: #4578bc;
}

.breadcrumb-link.current-bc-link {
	border-bottom-style: none;
}

.breadcrumb-link.current-bc-link:hover {
	color: #424242;
}

.left-nav-link-2 {
	display: block;
	margin-bottom: 0.5rem;
	padding: 2px 0rem 2px 0.63rem;
	-webkit-transition-duration: 200ms;
	transition-duration: 200ms;
	-webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	color: #000;
	font-size: 0.83rem;
	line-height: 1.2rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
}

.left-nav-link-2:hover {
	padding-right: 0rem;
	padding-left: 0.63rem;
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #2a5ea3;
}

.left-nav-link-2.w--current {
	box-shadow: inset 2px 0 0 0 #629edc;
	color: #4578bc;
}

.left-nav-link-2.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-link-2.greatgrandchild-link {
	padding-top: 1px;
	padding-bottom: 1px;
	background-image: url('/20170713122722/assets/images/bullet_ffffff.svg');
	background-position: 0px 50%;
	background-size: 4px 4px;
	background-repeat: no-repeat;
	font-size: 0.83rem;
	line-height: 1.13rem;
	font-weight: 500;
	text-transform: none;
}

.left-nav-link-2.greatgrandchild-link:hover {
	box-shadow: none;
}

.left-nav-link-2.greatgrandchild-link.w--current {
	background-image: url('/20170713122722/assets/images/bullet_629edc.svg');
	background-position: 0px 50%;
	background-size: 4px 4px;
	background-repeat: no-repeat;
	box-shadow: none;
	font-size: 0.83rem;
	line-height: 1.13rem;
	font-weight: 500;
}

.left-nav-link-2.grandchild-link {
	padding-top: 1px;
	padding-bottom: 1px;
	background-image: url('/20170713122722/assets/images/bullet_ffffff.svg');
	background-position: 0px 50%;
	background-size: 4px 4px;
	background-repeat: no-repeat;
	font-size: 0.93rem;
	line-height: 1.25rem;
	font-weight: 500;
	text-transform: none;
}

.left-nav-link-2.grandchild-link.w--current {
	background-image: url('/20170713122722/assets/images/bullet_629edc.svg');
	background-position: 0px 50%;
	background-size: 4px 4px;
	background-repeat: no-repeat;
	box-shadow: none;
}

.inside-page-wrapper {
	padding-top: 116px;
}

.right-col-item-wrap {
	padding-bottom: 0.4rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.session-message-ul {
	margin-bottom: 0rem;
}

.form-styles {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.error-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-top: 2px solid #e81c2e;
	border-bottom: 2px solid #e81c2e;
	background-color: rgba(255, 190, 195, 0.4);
	font-weight: 500;
}

.inside-content-section {
	padding-top: 153px;
}

.highlight {
	background-color: #f8ff5a;
}

.right-column-styles-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.breadcrumbs-wrapper {
	display: block;
	color: #424242;
	font-size: 0.8rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: none;
}

.field-label {
	font-weight: 500;
}

.success-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-top: 2px solid #4fb402;
	border-bottom: 2px solid #4fb402;
	background-color: hsla(94, 73%, 76%, 0.55);
	font-weight: 500;
}

html.w-mod-js *[data-ix="slide-reveal"] {
	opacity: 0;
	-webkit-transform: translate(300px, 0px);
	-ms-transform: translate(300px, 0px);
	transform: translate(300px, 0px);
}

html.w-mod-js *[data-ix="slide-header-reveal"] {
	opacity: 0;
	-webkit-transform: translate(300px, 0px);
	-ms-transform: translate(300px, 0px);
	transform: translate(300px, 0px);
}

html.w-mod-js *[data-ix="slide-caption-reveal"] {
	opacity: 0;
	-webkit-transform: translate(300px, 0px);
	-ms-transform: translate(300px, 0px);
	transform: translate(300px, 0px);
}

html.w-mod-js *[data-ix="insta-load"] {
	opacity: 0;
	-webkit-transform: translate(0px, 100px);
	-ms-transform: translate(0px, 100px);
	transform: translate(0px, 100px);
}

html.w-mod-js *[data-ix="insta-load-2"] {
	opacity: 0;
	-webkit-transform: translate(0px, 100px);
	-ms-transform: translate(0px, 100px);
	transform: translate(0px, 100px);
}

html.w-mod-js *[data-ix="insta-load-3"] {
	opacity: 0;
	-webkit-transform: translate(0px, 100px);
	-ms-transform: translate(0px, 100px);
	transform: translate(0px, 100px);
}

html.w-mod-js *[data-ix="row-2-cb-1-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 100px);
	-ms-transform: translate(0px, 100px);
	transform: translate(0px, 100px);
}

html.w-mod-js *[data-ix="row-2-cb2-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 100px);
	-ms-transform: translate(0px, 100px);
	transform: translate(0px, 100px);
}

html.w-mod-js *[data-ix="cb2-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="feature-slide-stage"] {
	-webkit-transform: scale(0.9400000000000006, 0.9400000000000006);
	-ms-transform: scale(0.9400000000000006, 0.9400000000000006);
	transform: scale(0.9400000000000006, 0.9400000000000006);
}

html.w-mod-js *[data-ix="feature-image"] {
	opacity: 0.5;
	-webkit-transform: scale(1.5, 1.5);
	-ms-transform: scale(1.5, 1.5);
	transform: scale(1.5, 1.5);
}

html.w-mod-js *[data-ix="zoom-in-slide"] {
	opacity: 0;
	-webkit-transform: translate(0px, -20px) scale(0.5, 0.5);
	-ms-transform: translate(0px, -20px) scale(0.5, 0.5);
	transform: translate(0px, -20px) scale(0.5, 0.5);
}

html.w-mod-js *[data-ix="hide-location-options"] {
	display: none;
}

html.w-mod-js *[data-ix="less-options"] {
	display: none;
}

html.w-mod-js *[data-ix="slide-caption-load"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="cb1-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="cb1-reveal-2"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="cb1-reveal-3"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="card-1-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="card-2-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="card-3-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="card-4-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="lower-heading-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="tab1-reveal"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="tab1-reveal-2"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="tab1-reveal-3"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="tab1-reveal-4"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="tab1-reveal-5"] {
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
}

html.w-mod-js *[data-ix="feature-text"] {
	opacity: 0;
	-webkit-transform: translate(-40px, 0px);
	-ms-transform: translate(-40px, 0px);
	transform: translate(-40px, 0px);
}

html.w-mod-js *[data-ix="hide-mobile-header-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="minislideshow-load"] {
	opacity: 0;
	-webkit-transform: translate(0px, 200px);
	-ms-transform: translate(0px, 200px);
	transform: translate(0px, 200px);
}

html.w-mod-js *[data-ix="intro-load"] {
	opacity: 0;
	-webkit-transform: translate(0px, 200px);
	-ms-transform: translate(0px, 200px);
	transform: translate(0px, 200px);
}

@media (max-width: 991px) {
	h1 {
		font-size: 2.25rem;
		line-height: 2.75rem;
	}

	h2 {
		font-size: 1.65rem;
		line-height: 2.35rem;
	}

	h3 {
		font-size: 1.4rem;
		line-height: 2.1rem;
	}

	h4 {
		font-size: 1.3rem;
		line-height: 1.9rem;
	}

	h5 {
		font-size: 1.2rem;
		line-height: 1.8rem;
	}

	h6 {
		font-size: 1.1rem;
		font-weight: 500;
	}

	.container {
		max-width: 768px;
	}

	.container.footer-container {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.container.signoff-container {
		padding-top: 2rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.container.news-container {
		margin-left: 0px;
	}

	.container.doc-rpt-container {
		margin-left: 0vw;
	}

	.container.page-title-container {
		padding-left: 2rem;
	}

	.container.inside-content-container {
		max-width: none;
		padding-top: 1rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.main-nav-link {
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		text-align: left;
	}

	.main-nav-link:hover {
		background-color: rgba(69, 120, 188, 0.35);
		color: #fff;
	}

	.main-nav-link.mobile-duplicate {
		display: block;
		width: 88%;
		padding: 0.75rem 1.25rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: none;
		background-color: transparent;
	}

	.main-nav-link.mobile-duplicate:hover {
		background-color: rgba(69, 120, 188, 0.35);
		background-image: none;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		color: #000;
	}

	.main-nav-link.mobile-duplicate.w--current {
		box-shadow: inset 6px 0 0 0 #f9b604;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		color: #f9b604;
	}

	.main-nav-link.mobile-duplicate.mobile-no-children {
		width: 100%;
	}

	.main-nav-link.mobile-duplicate.mobile-no-children:hover {
		background-color: #033466;
		background-image: none;
	}

	.main-nav-link.mobile-duplicate.mobile-no-children.w--current {
		background-color: rgba(69, 120, 188, 0.35);
		box-shadow: inset 4px 0 0 0 #4578bc;
		color: #000;
	}

	.main-nav-link.mobile-duplicate.mobile-no-children {
		width: 100%;
	}

	.main-nav-link.mobile-duplicate.mobile-no-children:hover {
		background-color: rgba(69, 120, 188, 0.35);
	}

	.main-nav-link.mobile-duplicate.mobile-no-children.w--current {
		background-color: rgba(69, 120, 188, 0.35);
		box-shadow: inset 4px 0 0 0 #4578bc;
		color: #000;
	}

	.slideshow-arrow.left-arrow {
		left: 2rem;
		overflow: hidden;
	}

	.home-content-section.hcs-row-1 {
		padding: 2rem 4vw 2.5rem;
	}

	.home-content-section.hcs-row-2 {
		padding-top: 0rem;
		padding-right: 0vw;
		padding-left: 0vw;
	}

	.home-content-section.hcs-row-3 {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.signoff-wrapper {
		margin-top: 0.75rem;
	}

	.copyright-paragraph {
		margin-right: 0px;
	}

	.menu-icon {
		display: block;
		width: 40px;
		height: 40px;
		margin-right: auto;
		margin-left: auto;
		padding: 3px 0px 0px;
		font-size: 2.15rem;
		line-height: 2.15rem;
		text-align: center;
	}

	.mobile-menu-arrow {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 12%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-left: 1px solid rgba(0, 0, 0, 0.2);
		color: #000;
	}

	.mobile-menu-arrow:hover {
		background-color: rgba(69, 120, 188, 0.35);
	}

	.footer-paragraph {
		font-size: 0.9rem;
		line-height: 1.6rem;
	}

	.footer-nav-list {
		-webkit-column-count: 1;
		column-count: 1;
	}

	.footer-nav-list-item {
		font-size: 0.9rem;
		line-height: 1.4rem;
	}

	.social-media-wrapper.footer-socmed-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.copyright-wrapper {
		margin-bottom: 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	}

	.locations-options-wrapper {
		display: block;
	}

	.more-options-button {
		-webkit-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start;
	}

	.privacy-terms-wrapper {
		margin-bottom: 0.75rem;
	}

	.copyright-privacy-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}

	.less-options-button {
		-webkit-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start;
	}

	.feature-card-heading {
		font-size: 1.7rem;
	}

	.cb-section-heading {
		font-size: 2.25rem;
	}

	.cb-section-heading.white-cb-head {
		padding-top: 0.5rem;
		padding-bottom: 0.6rem;
		background-color: #034a94;
		text-align: center;
		text-shadow: none;
	}

	.feature-cb-paragraph {
		font-size: 1.15rem;
		line-height: 2rem;
	}

	.footer-lower-parent {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.header-right-wrap {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.header-right-wrap.extranet-right {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-cb.footer-cb-1 {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.mobile-arrow {
		margin-right: 1.25rem;
		margin-left: 1.25rem;
	}

	.hero-caption-inner-wrap {
		position: static;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.content-box.callout-cb {
		max-width: 768px;
		padding: 2rem;
	}

	.content-box-wrap.cbw-row-1 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.mobile-menu-toggle {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 44px;
		height: 44px;
		margin-right: 3vw;
		padding: 0px;
		float: none;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-radius: 4px;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
		color: #000;
	}

	.mobile-menu-toggle:hover {
		background-color: transparent;
		color: #4578bc;
	}

	.mobile-menu-toggle.w--open {
		background-color: transparent;
		color: #000;
	}

	.nav-bar-widget {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 0.65rem;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.nav-outer-wrap {
		position: absolute;
		left: 0px;
		top: 0px;
		right: 0px;
		width: 100%;
		margin-top: 58px;
		padding-top: 8px;
		padding-left: 8px;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		background-color: #f9f9f9;
		box-shadow: -4px 4px 15px 0 rgba(0, 0, 0, 0.3);
		color: #fff;
	}

	.header-content-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.main-nav-text {
		color: #fff;
	}

	.login {
		font-weight: 500;
	}

	.english {
		font-weight: 500;
	}

	.find-flight-dd {
		display: block;
		margin-right: auto;
		margin-left: auto;
		box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.05);
	}

	.find-flight-dd.w--open {
		right: 0px;
		padding-top: 0rem;
		box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.05), -1px 1px 3px 0 rgba(0, 0, 0, 0.15), 1px 1px 3px 0 rgba(0, 0, 0, 0.15);
	}

	.extranet-button.home-news-button {
		min-width: 285px;
	}

	.alert-wrapper-2 {
		padding-top: 1.5rem;
		padding-right: 4.5rem;
	}

	.co-brand-wrap {
		width: auto;
		margin-bottom: 0.375rem;
	}

	.page-wrapper {
		padding-top: 141px;
	}

	.hero-image-wrap {
		width: 100%;
		height: 400px;
	}

	.hero-image-wrap.hero-1 {
		width: 100%;
	}

	.hero-image-wrap.hero-2 {
		width: 100%;
	}

	.hero-image-wrap.hero-3 {
		width: 100%;
	}

	.slideshow-header {
		font-size: 2.5rem;
		line-height: 3rem;
	}

	.slideshow-caption {
		width: 100%;
		font-size: 1.25rem;
		line-height: 1.75rem;
	}

	.hero-caption {
		position: static;
		display: block;
		width: 100%;
		height: auto;
		max-width: none;
		min-width: 0px;
		margin-top: 0px;
		margin-right: auto;
		margin-left: auto;
		padding-right: 2rem;
		padding-left: 2rem;
		background-color: transparent;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.slide-nav {
		left: 2rem;
		width: 200px;
	}

	.arrow-icon {
		left: 0px;
		right: 0px;
		bottom: 0px;
		color: hsla(0, 0%, 100%, 0.75);
	}

	.header-section {
		padding-top: 0.25rem;
		padding-right: 0vw;
		padding-bottom: 9px;
		background-color: #fff;
	}

	.hero-rotator {
		width: auto;
		height: auto;
	}

	.hero-rotator-section {
		height: auto;
		max-height: none;
		min-height: 0px;
	}

	.extranet-header-right-upper {
		padding-top: 0rem;
		padding-right: 3vw;
	}

	.news-list-wrap {
		width: 100%;
		margin-bottom: 2rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.cb-parent-wrap {
		margin-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.cb-parent-wrap.doc-rpt {
		display: block;
		margin-bottom: 0rem;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.table-more {
		font-size: 0.85rem;
	}

	.table-more:hover {
		padding-right: 1.4rem;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.doc-rpt-tab-widget {
		margin-top: 2.5rem;
		margin-bottom: 4rem;
		padding-right: 4vw;
		padding-left: 4vw;
		background-color: transparent;
	}

	.doc-rpt-tab-widget.tab-widget-right {
		margin-bottom: 0rem;
	}

	.cb-category-wrap {
		display: block;
		width: 100%;
		margin-bottom: 2rem;
	}

	.bg-image-wrapper {
		background-image: url('/20170713122722/assets/images/black-twill-flop-10_1black-twill-flop-10.png'), url('/20170713122722/assets/images/958511136-170667a.jpg');
		background-position: 0px 0px, 50% 50%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
		background-attachment: scroll, fixed;
	}

	.content-outer-wrap {
		height: 100%;
		padding-top: 0rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.intro-paragraph {
		font-size: 1.65rem;
		line-height: 2.35rem;
	}

	.left-nav-col {
		display: none;
	}

	.main-content-outer-wrap {
		display: block;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}

	.inside-page-banner-wrap {
		height: 250px;
	}

	.inside-page-banner-wrap.inside-banner-1 {
		height: 400px;
	}

	.main-content {
		width: 100%;
		padding-top: 1rem;
	}

	.right-side-col {
		width: 100%;
		max-width: none;
		min-width: 0px;
		margin-top: 2rem;
		margin-left: 0rem;
		padding-top: 0rem;
		border-top: 1px solid rgba(0, 0, 0, 0.15);
	}

	.main-content-inner-wrap {
		display: block;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.breadcrumb-link {
		margin-top: 0.25rem;
	}

	.inside-page-wrapper {
		padding-top: 140px;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.5rem;
		font-size: 0.9rem;
		line-height: 1.5rem;
	}
}

@media (max-width: 767px) {
	h1 {
		font-size: 2.15rem;
		line-height: 2.65rem;
	}

	h2 {
		margin-top: 1.25rem;
	}

	h3 {
		margin-bottom: 0.5rem;
		font-size: 1.35rem;
		line-height: 2.1rem;
	}

	h6 {
		font-size: 1rem;
		line-height: 1.6rem;
	}

	ul {
		padding-left: 20px;
	}

	ol {
		padding-left: 20px;
	}

	blockquote {
		font-size: 1.1rem;
		line-height: 1.8rem;
	}

	.container {
		max-width: 550px;
	}

	.container.footer-container {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.container.news-container {
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.container.doc-rpt-container {
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.container.page-title-container {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.container.inside-content-container {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.logo-link {
		width: 140px;
		margin-left: 2rem;
	}

	.main-nav-link.mobile-duplicate {
		width: 84%;
		padding-left: 1rem;
	}

	.slideshow-arrow.left-arrow {
		left: 2rem;
	}

	.home-content-section {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	.home-content-section.hcs-row-1 {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.home-content-section.hcs-row-3 {
		padding: 4rem 2rem;
	}

	.signoff-wrapper {
		margin-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.mobile-menu-arrow {
		width: 16%;
	}

	.footer-nav-list {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.footer-nav-list-item {
		display: inline-block;
		margin-right: 1.15rem;
		margin-bottom: 0.3rem;
	}

	.privacy-terms-wrapper {
		margin-bottom: 0.75rem;
	}

	.cb-section-heading {
		font-size: 2.25rem;
		line-height: 2.6rem;
	}

	.cb-section-heading.white-cb-head {
		line-height: 3.25rem;
	}

	.header-right-wrap {
		padding-top: 1rem;
	}

	.header-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-cb {
		width: 100%;
	}

	.footer-cb.footer-cb-1 {
		width: 100%;
		margin-bottom: 2rem;
	}

	.footer-cb.footer-cb-4 {
		width: 50%;
	}

	.footer-cb.footer-cb-3 {
		width: 50%;
		padding-right: 0.25rem;
	}

	.content-box.callout-cb {
		min-width: 416px;
	}

	.mobile-menu-toggle:hover {
		background-image: none;
	}

	.nav-outer-wrap {
		margin-top: 68px;
	}

	.header-content-wrap {
		padding-top: 40px;
		padding-bottom: 0px;
		-webkit-transition: all 300ms ease;
		transition: all 300ms ease;
	}

	.login {
		position: relative;
		top: 2px;
		width: 100%;
	}

	.english {
		width: 100%;
	}

	.find-flight-dd {
		width: 432px;
	}

	.find-flight-dd.w--open {
		right: 0px;
		width: 432px;
	}

	.alert-wrapper-2 {
		padding-left: 2rem;
	}

	.co-brand-wrap {
		top: 0.25rem;
		width: auto;
	}

	.page-wrapper {
		padding-top: 166px;
	}

	.airline-logo {
		max-width: 160px;
	}

	.hero-image-wrap {
		height: 300px;
	}

	.slideshow-header {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
		font-size: 2.15rem;
		line-height: 2.65rem;
	}

	.slideshow-caption {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.slide-nav {
		left: 2rem;
	}

	.arrow-icon.right-arrow-icon {
		background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent));
		background-image: linear-gradient(180deg, transparent, transparent);
	}

	.header-section {
		padding-bottom: 0rem;
		padding-left: 0rem;
		background-color: #fff;
	}

	.extranet-header-right-upper {
		position: absolute;
		left: 0px;
		top: 0px;
		right: 0px;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		padding: 0.3rem 3vw;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		-webkit-transition: all 300ms ease;
		transition: all 300ms ease;
	}

	.profile-nav-link {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.profile-dd-list {
		display: none;
	}

	.profile-dd-widget {
		margin-right: 0px;
		margin-left: 0px;
	}

	.news-feed-article-heading {
		font-size: 1.2rem;
		line-height: 1.7rem;
	}

	.doc-rpt-tab-widget {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.content-outer-wrap {
		padding-right: 1.5rem;
		padding-bottom: 2rem;
		padding-left: 1.5rem;
	}

	.inside-page-banner-wrap.inside-banner-1 {
		height: 300px;
	}

	.breadcrumbs-list {
		margin-bottom: 1.5rem;
	}

	.inside-page-wrapper {
		padding-top: 166px;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.25rem;
		font-size: 0.85rem;
	}
}

@media (max-width: 479px) {
	h1 {
		margin-bottom: 1rem;
		font-size: 1.75rem;
		line-height: 2.25rem;
	}

	h2 {
		font-size: 1.45rem;
		line-height: 2.15rem;
	}

	h3 {
		font-size: 1.3rem;
		line-height: 2rem;
	}

	h4 {
		font-size: 1.2rem;
		line-height: 1.8rem;
	}

	h5 {
		font-size: 1.05rem;
		line-height: 1.65rem;
	}

	p {
		font-size: 0.98rem;
		line-height: 1.65rem;
	}

	blockquote {
		font-size: 1.05rem;
		line-height: 1.75rem;
	}

	.container {
		max-width: none;
	}

	.container.footer-container {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.container.signoff-container {
		width: 100%;
	}

	.container.page-title-container {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.container.inside-content-container {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
		background-color: hsla(0, 0%, 100%, 0.9);
	}

	.logo-link {
		width: 110px;
		margin-left: 1.5rem;
	}

	.main-nav-link.mobile-duplicate {
		width: 80%;
	}

	.slideshow-arrow.left-arrow {
		left: 1rem;
	}

	.home-content-section {
		padding-bottom: 4.5rem;
		border-bottom: 1px none rgba(0, 0, 0, 0.1);
	}

	.home-content-section.hcs-row-1 {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.home-content-section.hcs-row-2 {
		padding-bottom: 0rem;
	}

	.home-content-section.hcs-row-3 {
		padding: 2rem 1rem 3rem;
	}

	.copyright-paragraph {
		margin-right: 1rem;
		margin-left: 1rem;
		line-height: 1.4rem;
	}

	.signoff-link {
		display: inline-block;
		margin-bottom: 0.25rem;
		line-height: 1.3rem;
	}

	.mobile-menu-arrow {
		position: relative;
		width: 20%;
	}

	.footer-nav-list {
		display: block;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.footer-nav-list-item {
		display: block;
	}

	.social-media-wrapper.footer-socmed-wrapper.social-2 {
		width: 100%;
	}

	.social-media-wrapper.footer-socmed-wrapper.social-2 {
		width: 100%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.privacy-terms-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.feature-card-heading {
		font-size: 1.5rem;
		line-height: 1.9rem;
	}

	.cb-section-heading {
		font-size: 2rem;
		line-height: 2.4rem;
	}

	.feature-cb-paragraph.white-feature-cb-para {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.header-right-wrap {
		padding-top: 0rem;
	}

	.footer-cb {
		width: 100%;
	}

	.footer-cb.footer-cb-1 {
		width: 100%;
		margin-bottom: 1.5rem;
	}

	.footer-cb.footer-cb-4 {
		width: 100%;
	}

	.footer-cb.footer-cb-3 {
		width: 100%;
		margin-bottom: 1rem;
	}

	.hero-caption-inner-wrap {
		padding-bottom: 0rem;
	}

	.content-box.callout-cb {
		min-width: 0px;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.social-media-link-block-2.footer-socmed-link-block {
		margin-right: 0.25rem;
		margin-left: 0.25rem;
	}

	.mobile-menu-toggle {
		margin-top: 17px;
		margin-right: 1rem;
	}

	.nav-outer-wrap {
		width: 100%;
		margin-top: 76px;
	}

	.login {
		position: relative;
		top: 2px;
		width: 100%;
	}

	.english {
		width: 100%;
	}

	.find-flight-dd {
		top: 73%;
		width: 272px;
	}

	.find-flight-dd.w--open {
		width: 272px;
	}

	.search-container-cover {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.extranet-button.home-news-button {
		min-width: 200px;
	}

	.alert-wrapper-2 {
		padding-top: 2.75rem;
		padding-right: 2rem;
	}

	.co-brand-wrap {
		width: auto;
	}

	.airline-logo-wrap {
		margin-right: 0.75rem;
		padding-right: 0.75rem;
	}

	.page-wrapper {
		padding-top: 150px;
	}

	.airline-logo {
		max-height: 40px;
		max-width: 100px;
	}

	.hero-image-wrap {
		height: 200px;
	}

	.slideshow-header {
		font-size: 1.85rem;
		line-height: 2.15rem;
	}

	.slideshow-caption {
		font-size: 1.15rem;
		line-height: 1.65rem;
	}

	.hero-caption {
		padding-right: 1rem;
		padding-left: 1rem;
		font-size: 0.925em;
	}

	.slide-nav {
		left: 1rem;
		background-color: transparent;
	}

	.arrow-icon {
		color: hsla(0, 0%, 100%, 0.85);
	}

	.arrow-icon.right-arrow-icon {
		background-image: url('/20170713122722/assets/images/rounded-tab-left-wht-50pct.svg');
		background-position: 112% 50%;
		background-size: 130px 40px;
		background-repeat: no-repeat;
	}

	.nav-dd-triangle-2 {
		left: 26px;
		top: 0px;
		right: auto;
	}

	.nav-dd-triangle-2.profile-dd-triangle.far-left-dd-triangle {
		left: auto;
		top: 0px;
		right: 26px;
	}

	.profile-dd-list {
		position: absolute;
		left: 0px;
		top: 43px;
		display: none;
	}

	.table-header {
		display: none;
	}

	.medium-cell {
		width: 100%;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.small-cell {
		width: 100%;
		min-width: 0px;
		padding-right: 1rem;
	}

	.table-row {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.doc-rpt-tab-widget {
		margin-top: 1rem;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.tab-menu {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.tablink {
		padding-right: 0.4rem;
		padding-left: 0.4rem;
		background-image: none;
		background-position: 0px 0px;
		background-size: auto;
		background-repeat: repeat;
		line-height: 1.3rem;
		text-align: center;
	}

	.tablink.w--current {
		background-image: none;
		background-position: 0px 0px;
		background-size: auto;
		background-repeat: repeat;
		font-size: 1rem;
		text-align: center;
	}

	.cb-category-wrap.doc-rpt-category {
		margin-bottom: 0rem;
	}

	.content-outer-wrap {
		padding-right: 1rem;
		padding-bottom: 2rem;
		padding-left: 1rem;
	}

	.intro-paragraph {
		font-size: 1.45rem;
		line-height: 2.15rem;
	}

	.inside-content-sect {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.inside-page-banner-wrap.inside-banner-1 {
		height: 175px;
	}

	.main-content {
		padding-top: 0.75rem;
	}

	.inside-page-wrapper {
		padding-top: 150px;
	}

	.breadcrumbs-wrapper {
		margin-top: 0rem;
	}
}

/****************** Webflow ***********************/
/****************************************************/

/* FONT SMOOTHING */
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}
/* CONTROL PHONE NUMBER COLOR */
a[href^=tel] {
	color: #000000;
	text-decoration: none;
}
/* CONTROL CURSOR CLICK & DRAG HIGHLIGHT COLOR */
/* Mozilla-based browsers */
::-moz-selection {
	background-color: #4578bc;
	color: #ffffff;
}
/* Safari browser */
::selection {
	background-color: #4578bc;
	color: #ffffff;
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
.header-outer-wrap a,
.header-outer-wrap a:hover {
	border-bottom: 0px;
}

.login-link:hover {
	box-shadow: inset 0 -2px 0 0 #629edc;
	color: #4578bc;
}

/*skip to main content WCAG link*/
.cms_form_button.primary.skip-to-main-content.sronly {
	padding: 0px;
	background-color: transparent;
}

.cms_form_button.primary.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	box-shadow: 0 0 5px #000;
	z-index: 2000;
	padding: 0.8rem 1rem;
	background-color: #4578bc;
}


/****************** Desktop menu *******************/
/*****************************************************/
.desktop-menu {
	width: 100%;
}

.desktop-menu .nav-outer-wrap {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
}

.desktop-menu .nav-outer-wrap li.main-nav-dd-widget {
	margin-bottom: 0px;
	margin-top: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	display: inline-block;
	position: relative;
	text-align: left;
}

.desktop-menu .nav-outer-wrap li.main-nav-dd-widget:hover a.main-nav-text,
.desktop-menu .nav-outer-wrap li.main-nav-dd-widget a.main-nav-text:hover,
.desktop-menu .nav-outer-wrap li.main-nav-dd-widget a.main-nav-text.active {
	box-shadow: inset 0 -2px 0 0 #629edc;
	color: #4578bc;
}

.desktop-menu .nav-outer-wrap li.main-nav-dd-widget .container-wrapper {
	position: absolute;
	background: #fff;
	width: 150px;
	opacity: 0;
	height: 0px;
	transition: opacity 100ms ease 0s, max-height 100ms ease 0s;
	left: 0;
	top: 100%;
	max-height: 0px;
	z-index: 100;
}

.desktop-menu .nav-outer-wrap li.main-nav-dd-widget.open .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

.desktop-menu .nav-outer-wrap li.main-nav-dd-widget .container-wrapper ul {
	list-style-type: none;
	margin: 0px;
	padding: 10px;
}

.desktop-menu .nav-outer-wrap li.main-nav-dd-widget .container-wrapper ul li {
	padding: 0px;
	margin: 0px;
}

.desktop-menu .nav-outer-wrap li.main-nav-dd-widget .container-wrapper ul li a {
	margin-top: 1px;
	margin-bottom: 1px;
	padding: 4px 10px;
	font-size: 0.85rem;
	font-weight: 500;
	color: #222222;
	display: inline-block;
	width: 100%;
	text-decoration: none;
}

.desktop-menu .nav-outer-wrap li.main-nav-dd-widget .container-wrapper ul li a:hover {
	box-shadow: inset 2px 0 0 0 #629edc;
	color: #4578bc;
}

@media (max-width: 991px) {
	.desktop-menu {
		display: none;
	}
}

/****************** Mobile Menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu {
	background: #f9f9f9;
}

.mobile-navigation-menu li a:hover {
	background: rgba(69, 120, 188, 0.35);
}

.mobile-navigation-menu li a.nav-a {
	padding: 0.75rem 1.25rem;
	margin-left: 10px;
	transition: all 150ms ease;
	color: #000;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 500;
	text-decoration: none;
}

.mobile-navigation-menu li.active a.nav-a {
	background-color: rgba(69, 120, 188, 0.35);
	box-shadow: inset 4px 0 0 0 #4578bc;
	color: #000;
}

.mm-listitem,
.mm-listitem .mm-btn_next,
.mm-listitem .mm-btn_next:before,
.mm-listitem:after,
.mm-listview {
	border-color: rgba(0, 0, 0, 0.2);
}

.mm-menu .mm-listview .mm-btn_next:after {
	border-color: #000000;
}

.mm-btn_next:after,
.mm-btn_prev:before {
	border-top: 1px solid transparent;
	border-left: 1px solid transparent;
}

.mm-listitem:after {
	left: 10px;
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + a,
.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + span {
	margin-right: 70px;
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
	width: 70px;
}

.mm-btn_next:after {
	right: 33px;
}

.mm-navbar {
	border-color: rgba(0, 0, 0, 0.2);
	margin-left: 10px;
	text-align: left;
	height: 43px;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar a:hover,
.mm-menu .mm-navbar > * {
	color: #000;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 500;
	border-bottom: 0px;
}

.mm-btn_prev:before {
	left: 18px;
	top: 3px;
}

.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
	border-color: #000000;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -17px;
}

.mm-navbar__title {
	padding-top: 12px;
}

/****************** Homepage ***********************/
/*************************************************/
.cb-section-heading a,
.cb-section-heading a:hover {
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	border-bottom: 0px;
}

a.cb-button,
a.cb-button:hover {
	border-bottom: 0px;
}

.hcs-row-3 * {
	color: #fff;
}

.hcs-row-3 h2 {
	margin-top: 0rem;
	color: #034a94;
	font-size: 2.5rem;
	line-height: 3.25rem;
	color: #fff;
	font-weight: 600;
}

.hcs-row-3 h2:after {
	display: block;
	width: 100px;
	height: 2px;
	margin: 0.75rem auto;
	background-color: #c3b9a4;
	content: "";
}

.hcs-row-3 p {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	font-size: 1.25rem;
	line-height: 2.1rem;
}

@media (max-width: 991px) {
	.hcs-row-3 p {
		font-size: 1.15rem;
		line-height: 2rem;
	}

	.hcs-row-3 h2 {
		font-size: 2.25rem;
	}
}


@media (max-width: 767px) {
	.hcs-row-3 h2 {
		font-size: 2.25rem;
		line-height: 2.6rem;
	}
}

@media (max-width: 479px) {
	.hcs-row-3 p {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.hcs-row-3 h2 {
		font-size: 2rem;
		line-height: 2.4rem;
	}
}

/* news content box */
#news_module.homepage_contentbox {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

#news_module.homepage_contentbox .cms_list_item {
	position: relative;
	width: 31%;
	margin-right: 3.5%;
	margin-left: 0rem;
	padding: 1.5rem 1.5rem 1rem;
	border-left: 4px solid #4578bc;
	background-color: rgba(206, 230, 247, 0.7);
	flex: 1 1 31%;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	#news_module.homepage_contentbox .cms_list_item {
		width: 30%;
		margin-right: 2%;
		flex: 1 1 25%;
	}
}

#news_module.homepage_contentbox .cms_list_item:nth-child(3) {
	margin-right: 0px;
}

#news_module.homepage_contentbox .cms_list_item .cms_title h3 {
	margin-top: 0rem;
	margin-bottom: 0.25rem;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

#news_module.homepage_contentbox .cms_list_item .cms_title h3 a {
	display: inline;
	border-bottom: 1px solid transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #034a94;
	font-weight: 600;
	text-decoration: none;
	cursor: pointer;
}

#news_module.homepage_contentbox .cms_list_item .cms_title h3 a:hover {
	border-bottom-color: rgba(69, 120, 188, 0.6);
}

#news_module.homepage_contentbox .cms_list_item .cms_date h3 {
	margin-top: 10px;
	padding-left: 20px;
	background-image: url("/20170713122722/assets/images/calendar.svg");
	background-position: 0px 50%;
	background-size: 15px 15px;
	background-repeat: no-repeat;
	color: #424242;
	font-size: 0.8em;
	line-height: 1.3rem;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 1rem;
	font-family: 'Encode Sans', sans-serif;
}

#news_module.homepage_contentbox > a:last-child {
	width: 20%;
	height: auto;
	display: inline-block;
	padding: 0.8rem 1rem;
	border-radius: 4px;
	background-color: #4578bc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	line-height: 1rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	margin: 3rem auto 1rem auto;
}

#news_module.homepage_contentbox > a:last-child:hover {
	background-color: #2a5ea3;
	color: #fff;
}

@media (max-width: 991px) {
	#news_module.homepage_contentbox {
		margin-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	#news_module.homepage_contentbox .cms_list_item {
		width: 100%;
		margin-bottom: 2rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		margin-right: 0px;
	}

	#news_module.homepage_contentbox > a:last-child {
		min-width: 285px;
		margin-top: 1rem;
	}
}

@media (max-width: 767px) {
	#news_module.homepage_contentbox .cms_list_item .cms_title h3 {
		font-size: 1.2rem;
		line-height: 1.7rem;
	}
}

@media (max-width: 479px) {
	#news_module.homepage_contentbox > a:last-child {
		min-width: 200px;
	}
}

/****************** Slideshow ********************/
/*************************************************/
.hero-rotator-section {
	position: relative;
	display: block;
	height: 60vh;
	max-height: 620px;
	min-height: 570px;
	margin-bottom: 0;
}

.hero-slide {
	overflow: hidden;
}

.hero-image-wrapper {
	position: relative;
	overflow: hidden;
	width: 80%;
	height: 100%;
	float: right;
	background-position: 0 0, 50% 50%;
}

.hero-image-wrapper,
.hero-image-wrapper.corp-hero-1 {
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.slider-caption {
	position: absolute;
	left: 0;
	top: 50%;
	bottom: 0;
	width: 50%;
	height: 100%;
	max-width: 650px;
	min-width: 545px;
	margin-left: 4vw;
	padding-bottom: 4rem;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	transform: translate(0, -50%);
	color: #fff;
	font-size: 1.125em;
	line-height: 1.65em;
}

.slide-caption-inner-wrap {
	position: relative;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	z-index: 1000;
}

.slideshow-header {
	max-width: none;
	border-left: 4px solid #4578bc;
	font-size: 2.75rem;
	line-height: 3.15rem;
	font-weight: 600;
	letter-spacing: 0.03rem;
}

.slideshow-header {
	display: block;
}

.slideshow-caption,
.slideshow-header {
	padding: 0.5rem 1rem;
	float: left;
	background-color: hsla(0, 0%, 100%, 0.75);
	color: #000;
}

.slideshow-caption {
	max-width: 650px;
	margin-bottom: 0.7rem;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 300;
}

.slideshow-caption {
	display: block;
	clear: left;
}

.button.slideshow-button,
.slideshow-caption,
.slideshow-header {
	opacity: 0;
	-webkit-transform: translate(300px, 0);
	-ms-transform: translate(300px, 0);
	transform: translate(300px, 0);
	transition: none;
}

.button.slideshow-button {
	display: inline-block;
	height: auto;
	margin-top: 0.75rem;
	margin-right: 0;
	margin-bottom: 0.5rem;
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
	float: left;
	clear: left;
	background-color: #4578bc;
	font-size: 0.85em;
	line-height: 1.5rem;
}

.button.slideshow-button,
.slideshow-caption,
.slideshow-header {
	opacity: 0;
	-webkit-transform: translate(300px, 0);
	-ms-transform: translate(300px, 0);
	transform: translate(300px, 0);
	transition: none;
}

.slideshow-arrow {
	left: auto;
	top: auto;
	right: 0;
	bottom: 0;
	width: 2rem;
	height: 2rem;
}

.slidenav,
.slideshow-arrow.left-slide-arrow,
.slideshow-arrow.right-slide-arrow {
	left: 4vw;
	right: auto;
	bottom: 1rem;
	display: block;
}

.slideshow-arrow.left-slide-arrow {
	border-radius: 50%;
	background-color: rgba(69, 120, 188, 0.75);
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
}

.arrow-icon.right-arrow-icon {
	left: 0;
	right: 0;
	display: block;
	margin: auto;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.slideshow-arrow.right-slide-arrow {
	margin-left: 2.4rem;
	border-radius: 50%;
	background-color: rgba(69, 120, 188, 0.75);
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
}

.slidenav {
	width: 100px;
	height: 2rem;
	margin-right: auto;
	margin-left: 5rem;
	padding-top: 13px;
	font-size: 0.65rem;
	text-align: left;
}

.hero-rotator-section .w-slider-dot {
	background: rgba(255, 255, 255, 0.4);
}

.hero-rotator-section .w-slider-dot.w-active {
	background: #fff;
}

@media (max-width: 991px) {
	.hero-rotator-section {
		height: auto;
		max-height: none;
		min-height: 0;
	}

	.hero-rotator {
		width: auto;
		height: auto;
	}

	.hero-image-wrapper {
		width: 100%;
		height: 400px;
	}

	.hero-image-wrapper.hero-1 {
		width: 100%;
	}

	.slider-caption {
		position: static;
		display: block;
		width: 100%;
		height: auto;
		max-width: none;
		min-width: 0;
		margin-top: 0;
		margin-right: auto;
		margin-left: auto;
		padding-right: 2rem;
		padding-bottom: 3.5rem;
		padding-left: 2rem;
		background-color: transparent;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.slide-caption-inner-wrap {
		position: static;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.slideshow-header {
		font-size: 2.5rem;
		line-height: 3rem;
	}

	.slideshow-caption {
		width: 100%;
		font-size: 1.25rem;
		line-height: 1.75rem;
	}

	.slideshow-arrow.left-arrow,
	.slideshow-arrow.left-slide-arrow {
		overflow: hidden;
		left: 2rem;
	}

	.slideshow-arrow.right-slide-arrow {
		left: 2rem;
	}

	.slidenav {
		left: 2rem;
		width: 200px;
	}
}

@media (max-width: 767px) {

	.hero-image-wrapper {
		height: 300px;
	}

	.slideshow-caption,
	.slideshow-header {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.slideshow-header {
		font-size: 2.15rem;
		line-height: 2.65rem;
	}

	.slideshow-caption,
	.slideshow-header {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.slidenav,
	.slideshow-arrow.left-arrow,
	.slideshow-arrow.left-slide-arrow,
	.slideshow-arrow.right-arrow,
	.slideshow-arrow.right-slide-arrow {
		left: 2rem;
	}

	.arrow-icon.right-arrow-icon {
		background-image: -webkit-linear-gradient(270deg, transparent, transparent);
		background-image: linear-gradient(180deg, transparent, transparent);
	}

	.slidenav {
		left: 2rem;
		width: 200px;
	}
}

@media (max-width: 479px) {
	.hero-image-wrapper {
		height: 200px;
	}

	.slider-caption {
		padding-right: 1rem;
		padding-left: 1rem;
		font-size: 0.925em;
	}

	.slide-caption-inner-wrap {
		padding-bottom: 0;
	}

	.slideshow-header {
		font-size: 1.85rem;
		line-height: 2.15rem;
	}

	.slideshow-caption {
		font-size: 1.15rem;
		line-height: 1.65rem;
	}

	.slidenav,
	.slideshow-arrow.left-arrow,
	.slideshow-arrow.left-slide-arrow,
	.slideshow-arrow.right-arrow,
	.slideshow-arrow.right-slide-arrow {
		left: 1rem;
	}

	.arrow-icon.right-arrow-icon {
		background-image: url("/20170713122722/assets/images/rounded-tab-left-wht-50pct.svg");
		background-position: 112% 50%;
		background-size: 130px 40px;
		background-repeat: no-repeat;
	}
}

.slideshow-caption p:last-child {
	margin-bottom: 0px;
}

.button.slideshow-button:hover {
	display: inline-block;
	height: auto;
	margin-top: 0.75rem;
	margin-right: 0rem;
	margin-bottom: 0.5rem;
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
	float: left;
	clear: left;
	font-size: 0.85em;
	line-height: 1.5rem;
}

.slideshow-caption {
	display: block;
	clear: left;
}

.slideshow-header {
	display: block;
}

.slideshow-header,
.slideshow-caption,
.button.slideshow-button {
	opacity: 0;
	-webkit-transform: translate(300px, 0px);
	-ms-transform: translate(300px, 0px);
	transform: translate(300px, 0px);
	transition: none;
}

.cycle-slide-active .slideshow-header {
	opacity: 1;
	transform: translateX(0px) translateY(0px) translateZ(0px);
	transition: opacity 900ms ease 0s, transform 700ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.cycle-slide-active .slideshow-caption {
	opacity: 1;
	transform: translateX(0px) translateY(0px) translateZ(0px);
	transition: opacity 900ms ease 0s, transform 1100ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.cycle-slide-active .button.slideshow-button {
	opacity: 1;
	transform: translateX(0px) translateY(0px) translateZ(0px);
	transition: opacity 900ms ease 0s, transform 1100ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s, background-color 200ms ease-out;
}

@media (max-width: 991px) {
	.w-slide {
		height: auto;
	}
}

/****************** Inside Page *******************/
/*************************************************/
.main-content-inner-wrap {
	min-height: 500px;
}

.left-nav-ul li a.active {
	box-shadow: inset 2px 0 0 0 #629edc;
	color: #4578bc;
}

.left-nav-ul li li a.active {
	background-image: url("/20170713122722/assets/images/bullet_629edc.svg");
	background-position: 0px 50%;
	background-size: 4px 4px;
	background-repeat: no-repeat;
	box-shadow: none;
}

.breadcrumbs-list li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumbs-list li:last-child a:hover {
	color: #424242;
	border-bottom: 0px;
}

a.breadcrumb-link {
	font-weight: normal;
}

.left-nav-wrapper a,
.left-nav-wrapper a:hover {
	border-bottom: 0px;
}

td img {
	max-width: none;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/******************* Right Sidebar *******************/
/*****************************************************/
.right-side-col .box,
.left-nav-col .box {
	padding-bottom: 1rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	margin-top: 1.25rem;
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 400;
}

.right-side-col .box *,
.left-nav-col .box * {
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.right-side-col .box h4,
.right-side-col .box h3,
.left-nav-col .box h4,
.left-nav-col .box h3 {
	margin-top: 0px;
	margin-bottom: 0.25rem;
	color: #034a94;
	font-size: 1.1rem;
	line-height: 1.6rem;
	font-family: Raleway, sans-serif;
	font-weight: 600;
}

.right-side-col .box p,
.left-nav-col .box p {
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 400;
}

.right-side-col .box ul,
.left-nav-col .box ul {
	font-size: 0.9rem;
	line-height: 1.4rem;
}


.right-side-col .box td,
.left-nav-col .box td {
	vertical-align: top;
	padding-bottom: 0.5rem;
}

.right-side-col .box .contentbox_item_image {
	width: 24px;
}

.right-side-col .box.documents td:first-child {
	padding-right: 7px;
}

.right-side-col .box.links p {
	margin-bottom: 0.25rem;
	margin-top: 0px;
}

.right-side-col .box.tools td:first-child img {
	margin-right: 5px;
}

/******************** Footer  *************************/
/*****************************************************/
.footer-section-wrap a,
.footer-section-wrap a:hover {
	border-bottom: 0px;
}

.phone-link.mobile {
	display: none;
}

.phone-link.desktop {
	display: inline;
}

.footer-list-item-link.active {
	box-shadow: inset 0 -1px 0 0 #629edc;
}

#GRIPFooterLogo {
	opacity: 0.75;
	line-height: 11px;
	float: right;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-top: 0px !important;
	transition: all 200ms ease;
}

#GRIPFooterLogo:hover {
	opacity: 1;
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}

@media (max-width: 991px) {
	.phone-link.mobile {
		display: inline;
	}

	.phone-link.desktop {
		display: none;
	}

	#GRIPFooterLogo {
		float: none;
		margin-right: auto;
		margin-left: auto;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
body a {
	border-bottom: 1px solid #629edc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #034a94;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

body a:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	border-bottom: 1px solid #629edc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #034a94;
	text-decoration: none;
	cursor: pointer;
	font-weight: inherit;
	font-size: inherit;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	border-bottom-color: transparent;
	color: #7c8186;
}

/* buttons */
.inside-body-section .button,
.primary,
.inside-body-section button[type="submit"],
.inside-body-section .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
button[type=submit],
.button {
	padding: 0.8rem 1rem;
	-webkit-box-pack: center;
	border-radius: 4px;
	background-color: #4578bc;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	line-height: 1rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	border: 0px;
	text-align: center;
}

.primary:hover,
.inside-body-section button[type="submit"]:hover,
.inside-body-section .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
button[type=submit]:hover,
.button:hover {
	background-color: #2a5ea3;
	color: #fff;
	padding: 0.8rem 1rem;
}

.secondary,
.inside-body-section button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-body-section .cms_form_button.secondary,
.inside-body-section .button.secondary {
	padding: 0.8rem 1rem;
	-webkit-box-pack: center;
	border-radius: 4px;
	background-color: #d6dce0;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #2e2e2e;
	line-height: 1rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	border: 0px;
	text-align: center;
}

.secondary:hover,
.inside-body-section button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-body-section .cms_form_button.secondary:hover,
.inside-body-section .button.secondary:hover {
	border-color: #221f1f;
	background-color: #c4ccd1;
	background-image: none;
	opacity: 1;
}

.tertiary,
.inside-body-section button[type="submit"].tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	padding: 0.25rem 0.75rem;
	-webkit-box-pack: center;
	border-radius: 4px;
	background-color: transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #585b5d;
	line-height: 1.3rem;
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	border: 2px solid #c4ccd1;
	text-align: center;
}

.tertiary:hover,
.inside-body-section button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	background-color: #c4ccd1;
	color: #000;
	padding: 0.25rem 0.75rem;
}

/* messages */
#message.success {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-top: 2px solid #4fb402;
	border-bottom: 2px solid #4fb402;
	background-color: hsla(94, 73%, 76%, 0.55);
	font-weight: 500;
	font-family: 'Encode Sans', sans-serif;
	color: #000;
	font-size: 1rem;
	line-height: 1.6rem;
}

#message.error {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-top: 2px solid #e81c2e;
	border-bottom: 2px solid #e81c2e;
	background-color: rgba(255, 190, 195, 0.4);
	font-weight: 500;
}

#message.success *,
#message.error * {
	font-weight: 500;
	font-family: 'Encode Sans', sans-serif;
	color: #000;
	font-size: 1rem;
	line-height: 1.6rem;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	background-color: white;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid #2e2e2e;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #033466;
	color: white;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #034a94;
	color: #ffffff;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: none;
	transition: none;
}

table.styled th a:hover {
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
	transition: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #ebeff2;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-page-wrapper input[type="text"],
.inside-page-wrapper input[type="tel"],
.inside-page-wrapper input[type="password"],
.inside-page-wrapper input[type="email"],
.inside-page-wrapper input[type="search"],
.inside-page-wrapper input[type="file"],
.inside-page-wrapper select,
.inside-page-wrapper textarea {
	max-width: 100%;
	padding: 8px 12px;
	font-size: 0.875rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

.inside-page-wrapper textarea {
	resize: vertical;
}

.inside-page-wrapper label {
	font-weight: 500;
	display: inline;
	margin-bottom: 5px;
	font-size: 0.8571rem;
	line-height: 1.5rem;
	font-family: 'Encode Sans', sans-serif;
	color: #000;
}

.cms_label {
	font-weight: 500;
	font-size: 0.8571rem;
	line-height: 1.5rem;
	font-family: 'Encode Sans', sans-serif;
	color: #000;
}

@media (max-width: 991px) {
	.inside-page-wrapper input[type="text"],
	.inside-page-wrapper input[type="tel"],
	.inside-page-wrapper input[type="password"],
	.inside-page-wrapper input[type="email"],
	.inside-page-wrapper input[type="search"],
	.inside-page-wrapper input[type="file"],
	.inside-page-wrapper select,
	.inside-page-wrapper textarea {
		width: 100%;
	}
}

/*blockquotes*/
blockquote p {
	margin-top: 0;
	margin-bottom: 0;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

/* date picker */
#ui-datepicker-div a {
	border-bottom: 0px;
}


/********************** Modules *********************/
/*****************************************************/
/* accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 256px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid #e9ece3;
	margin: 20px 0;
	padding-bottom: 7px;
	color: #4578bc;
	font-size: 1.1rem;
	line-height: 1.3em;
	font-weight: 500;
	font-family: 'Open Sans', sans-serif;
}

/*edit address form*/
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* news */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/* gdpr */
#cookie-consent-form-container form .consent-header img {
	margin: 0 auto;
	display: block;
	float: none;
}

/* Homepage

/* Reports */
.report-table.styled tr.totals-row td,
.report-table.styled tr td.totals-cell {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #034a94;
	color: #fff;
}

/****************** Content Boxes *******************/
/*****************************************************/

span.underline-text {
	text-decoration: underline;
}
